import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/var/jenkins_home/workspace/affilate_mono_repo_alltomtraning/gatsby/src/components/mdxLayout.js";
import { graphql, Link } from "gatsby";
import ProductsDisplay from "../../components/productDisplay.js";
import CategoriesLinks from "../../components/categoriesLinks.js";
export const query = graphql`
  query {
    allMongodbStoreProducts(sort: {fields: popularity_score, order: DESC}, filter: {brand: { eq: "c.p. sports" }}) {
      edges {
        node {
          	brand
			full_name
			id
			local_image_name
			model
			original_prices
			popularity_score
			prices
			product_id
			similair_products
			show_on_pages
			sub_product_type
			store
			url
			engine
			last_updated_date
			derived_properties {
				size
				length
				weight
			}
			fields {
				brand_url
				full_name
				full_name_url
				model_url
			}
        }
      }
    }
  }
`;
export const _frontmatter = {};
const layoutProps = {
  query,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "cp-sports-träningsutrustning"
    }}>{`C.P. Sports Träningsutrustning`}</h1>
    <p>{`C.P. Sports erbjuder ett brett utbud av högkvalitativ träningsutrustning designad för att maximera din prestation och komfort. Från styrkelyftarbälten till träningshandskar och stödbandage, har C.P. Sports allt du behöver för att ta din träning till nästa nivå. Här presenterar vi de olika produkterna som C.P. Sports erbjuder, samt en kort köpguide som hjälper dig att välja rätt utrustning för dina behov.`}</p>
    <h2 {...{
      "id": "knälindor"
    }}>{`Knälindor`}</h2>
    <p>{`C.P. Sports knälindor är perfekta för att ge extra stöd och stabilitet under tunga lyft, såsom benböj och andra intensiva benövningar. Dessa knälindor är tillverkade i en blandning av polyester och gummi, vilket ger både hållbarhet och flexibilitet. Finns i flera färgkombinationer som svart/gult, svart/rosa, och svart/blått, vilket ger en visuell variation för din träningsutrustning.`}</p>
    <p><strong parentName="p">{`Nyckelfunktioner:`}</strong></p>
    <ul>
      <li parentName="ul">{`Mått: 2 meter i längd, 7,5 cm i bredd`}</li>
      <li parentName="ul">{`Justerbar hårdhet för optimalt stöd`}</li>
      <li parentName="ul">{`Tillverkade av 80% polyester och 20% gummi`}</li>
    </ul>
    <h2 {...{
      "id": "powerlifting-lever-belt"
    }}>{`Powerlifting Lever Belt`}</h2>
    <p>{`Powerlifting Lever Belt från C.P. Sports är tillverkade för seriösa lyftare som kräver både stil och funktion. Dessa bälten är gjorda av 100% buffaloläder och kommer med ett snabbspänne för enkel justering mellan seten. Du hittar dem i olika färger och mönster, som svart, camo och en rad storlekar från xsmall till xxlarge.`}</p>
    <p><strong parentName="p">{`Nyckelfunktioner:`}</strong></p>
    <ul>
      <li parentName="ul">{`100% buffaloläder`}</li>
      <li parentName="ul">{`Robust snabbspänne för snabb justering`}</li>
      <li parentName="ul">{`Finns i flera färger och storlekar`}</li>
    </ul>
    <h2 {...{
      "id": "maxi-grip-gloves"
    }}>{`Maxi Grip Gloves`}</h2>
    <p>{`Maxi Grip Gloves från C.P. Sports erbjuder en kombination av komfort och grepp, perfekt för tyngdlyftning och allround träning. Dessa träningshandskar är gjorda av en blandning av polyuretan, nylon, polyester och neopren, vilket gör dem både slitstarka och ventilerande.`}</p>
    <p><strong parentName="p">{`Nyckelfunktioner:`}</strong></p>
    <ul>
      <li parentName="ul">{`Perfekt för både män och kvinnor`}</li>
      <li parentName="ul">{`Ventilerande material`}</li>
      <li parentName="ul">{`Maskintvättbara`}</li>
      <li parentName="ul">{`Finns i storlekar från xsmall till xxlarge`}</li>
    </ul>
    <h2 {...{
      "id": "elbow-wraps-pro"
    }}>{`Elbow Wraps Pro`}</h2>
    <p>{`Elbow Wraps Pro är designade för att ge optimalt stöd under pressövningar och samtidigt erbjuda maximal komfort. Dessa armbågslindor är tillverkade av en blandning av polyester och gummi, vilket gör dem både flexibla och hållbara.`}</p>
    <p><strong parentName="p">{`Nyckelfunktioner:`}</strong></p>
    <ul>
      <li parentName="ul">{`Mått: 130 cm i längd, 8 cm i bredd`}</li>
      <li parentName="ul">{`Justerbara kardborreband för säker passform`}</li>
      <li parentName="ul">{`Finns i flera färger som svart/orange och svart/rosa`}</li>
    </ul>
    <h2 {...{
      "id": "waist-support"
    }}>{`Waist Support`}</h2>
    <p>{`C.P. Sports Waist Support är ett neopren stödbälte som erbjuder ökat stöd för rygg och bål under träningspass. Detta bälte kombinerar komfort med hållbarhet och är perfekt för intensiva träningspass.`}</p>
    <p><strong parentName="p">{`Nyckelfunktioner:`}</strong></p>
    <ul>
      <li parentName="ul">{`Tillverkad av 90% neopren och 10% läder`}</li>
      <li parentName="ul">{`Justerbar för att passa de flesta storlekar`}</li>
      <li parentName="ul">{`Finns i färger som svart och rosa`}</li>
    </ul>
    <h2 {...{
      "id": "figure-8-straps"
    }}>{`Figure 8 Straps`}</h2>
    <p>{`För de som söker att förbättra sitt grepp vid tunga lyft, erbjuder C.P. Sports Figure 8 Straps. Dessa lyftremmar är gjorda av slitstark bomull och finns i olika färgalternativ som svart/röd och white camo.`}</p>
    <p><strong parentName="p">{`Nyckelfunktioner:`}</strong></p>
    <ul>
      <li parentName="ul">{`Tillverkade av bomull för hållbarhet`}</li>
      <li parentName="ul">{`Finns i flera storlekar och färgkombinationer`}</li>
      <li parentName="ul">{`Perfekt för användning vid tunga lyft och andra styrkeövningar`}</li>
    </ul>
    <h2 {...{
      "id": "padded-leather-lifting-straps"
    }}>{`Padded Leather Lifting Straps`}</h2>
    <p>{`C.P. Sports Padded Leather Lifting Straps kombinerar bekvämlighet med funktionalitet. Dessa vadderade läderremmar är byggda för att hantera de tuffaste lyften, och de erbjuder en tjock neoprenvaddering för att ytterligare förbättra komforten.`}</p>
    <p><strong parentName="p">{`Nyckelfunktioner:`}</strong></p>
    <ul>
      <li parentName="ul">{`Tillverkade av högkvalitativt läder`}</li>
      <li parentName="ul">{`5 mm neoprenvaddering`}</li>
      <li parentName="ul">{`Finns i färger som svart och brun`}</li>
    </ul>
    <h2 {...{
      "id": "dip-belt-pro"
    }}>{`Dip Belt Pro`}</h2>
    <p>{`Dip Belt Pro från C.P. Sports är det perfekta verktyget för seriösa atleter som vill maximera sina pull-ups, dips och chins. Detta bälte har en hög maxbelastning och är tillverkad av slitstarkt nylon för att hantera tunga viktskivor.`}</p>
    <p><strong parentName="p">{`Nyckelfunktioner:`}</strong></p>
    <ul>
      <li parentName="ul">{`Maxbelastning på 140 kg`}</li>
      <li parentName="ul">{`Tillverkad i slitstarkt nylon`}</li>
      <li parentName="ul">{`Robust kedja för viktskivor med minst 25 mm håldiameter`}</li>
    </ul>
    <h2 {...{
      "id": "grip-pads"
    }}>{`Grip Pads`}</h2>
    <p>{`Grip Pads från C.P. Sports är det perfekta alternativet till traditionella träningshandskar. Tillverkade av 100% gummi, dessa pads erbjuder ett fast grepp under lyftövningar och är ideala för hantelträning.`}</p>
    <p><strong parentName="p">{`Nyckelfunktioner:`}</strong></p>
    <ul>
      <li parentName="ul">{`Tillverkade av 100% gummi`}</li>
      <li parentName="ul">{`Finns i storlekar som Small (S)`}</li>
      <li parentName="ul">{`Halkfria för ett säkrare grepp`}</li>
    </ul>
    <h2 {...{
      "id": "köpguide"
    }}>{`Köpguide`}</h2>
    <h3 {...{
      "id": "vilken-produkt-från-cp-sports-passar-dig"
    }}>{`Vilken produkt från C.P. Sports passar dig?`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Knälindor:`}</strong>{` Utmärkt för de som fokuserar på tunga benövningar som kräver extra stöd för knäna.`}</li>
      <li parentName="ul"><strong parentName="li">{`Powerlifting Lever Belt:`}</strong>{` Perfekt för seriösa lyftare som behöver maximal support och snabb justeringar mellan set.`}</li>
      <li parentName="ul"><strong parentName="li">{`Maxi Grip Gloves:`}</strong>{` Idealisk för allmän träning och tyngdlyftning, särskilt om du behöver komfort och bra grepp.`}</li>
      <li parentName="ul"><strong parentName="li">{`Elbow Wraps Pro:`}</strong>{` Perfekt för de som ofta utför pressövningar och behöver stabilitet och stöd för armbågarna.`}</li>
      <li parentName="ul"><strong parentName="li">{`Waist Support:`}</strong>{` Bäst för dem som behöver extra rygg- och bålstöd under intensiv träning.`}</li>
      <li parentName="ul"><strong parentName="li">{`Figure 8 Straps:`}</strong>{` Ett måste för dem som fokuserar på tunga lyft där greppet ofta är begränsningen.`}</li>
      <li parentName="ul"><strong parentName="li">{`Padded Leather Lifting Straps:`}</strong>{` För seriösa lyftare som kräver både komfort och hållbarhet.`}</li>
      <li parentName="ul"><strong parentName="li">{`Dip Belt Pro:`}</strong>{` Idealisk för styrketräning som kräver extra viktbelastning som pull-ups och dips.`}</li>
      <li parentName="ul"><strong parentName="li">{`Grip Pads:`}</strong>{` Perfekt alternativ till traditionella handskar för dem som fokuserar på greppövningar.`}</li>
    </ul>
    <p>{`Uppgradera din träningsutrustning idag med C.P. Sports och upptäck hur rätt utrustning kan göra en stor skillnad!`}</p>
    <ProductsDisplay data={props.data.allMongodbStoreProducts} location={props.location} mdxType="ProductsDisplay"></ProductsDisplay>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      